<template>
  <ion-page>
    <ion-content :scroll-y="false">
      <!-- allowTouchMove: false -->
      <ion-slides
        ref="slides"
        :options="{ slidesPerView: '1', zoom: false, allowTouchMove: false }"
        class="slides"
        @ionSlideDidChange="ionSlideDidChange"
      >
        <ion-slide>
          <div class="background--login" />
          <ion-content
            :scroll-y="false"
            class="ion-padding welcome-content"
          >
            <ion-row>
              <ion-col>
                <ion-text
                  class="text-larger text--white"
                >
                  {{ $t('Welcome.') }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row
              class="middle-content mt-20"
            >
              <img
                :src="require('@/assets/images/homevision.svg')"
              >
            </ion-row>
            <ion-row class="mt-30">
              <ion-col class="text-center welcome-text-intro text--gray">
                {{ $t('selectGatewayModelInstruction') }} <br><br>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div
                  :class="selectedGatewayModel === 'SEG-X3' ? 'active' : ''"
                  class="model-button rounded-corners"
                  @click="selectedGatewayModel = 'SEG-X3'"
                >
                  <img
                    class="mt-20"
                    style="max-width: 100px;"
                    :src="require('@/assets/devices/gateway.png')"
                  >
                  <div
                    class="text--primary mt-10"
                  >
                    <!-- Wireless Gateway -->
                  </div>
                </div>
              </ion-col>
              <ion-col>
                <div
                  class="model-button rounded-corners"
                  :class="selectedGatewayModel === 'SEG-X5' ? 'active' : ''"
                  @click="selectedGatewayModel = 'SEG-X5'"
                >
                  <img
                    style="max-width: 135px; margin-top: 35px;"
                    :src="require('@/assets/devices/gateway2.png')"
                  >
                  <div
                    class="text--primary mt-20"
                  >
                    <!-- Wired Gateway -->
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <div
              class="footer"
            >
              <ion-button
                color="medium"
                class="mt-8"
                @click="LogOut"
              >
                {{ $t('skip for now') }}
              </ion-button>
              <ion-button
                v-show="selectedGatewayModel"
                class="mt-8"
                @click="nextSlide"
              >
                <ion-label>{{ $t('Lets Begin') }}</ion-label>
              </ion-button>
            </div>
          </ion-content>
        </ion-slide>
        <ion-slide>
          <div class="background--login" />
          <ion-content
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
                @click="previousSlide"
              />
            </ion-toolbar>
            <div>
              <ion-text
                class="text-larger text--white"
              >
                {{ $t('PrepareConnectionGWSetup') }}
              </ion-text>
            </div>
            <ion-grid class="grid">
              <ion-row
                v-if="selectedGatewayModel === 'SEG-X3'"
                class="mt-30"
              >
                <ion-col>
                  <!-- <div class="welcome-text-intro text-center text--gray middle-content">
                  <span>{{ $t('welcomeText') }}</span>
                </div> -->
                  <div
                    class="text--gray"
                  >
                    <span>{{ $t('introTextGWFlashPurple') }}</span>
                    <div
                      class="mt-10 text--gray"
                      style="text-decoration: underline;"
                      @click="openLink"
                    >
                      Gateway manual
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="loader-wrapper"
                    >
                      <div class="back-circle-loader">
                        <div class="loader-anim-zigbee-version" />
                        <div class="inner-loader" />
                        <div class="inner-loader-frame" />
                      </div>
                      <div class="laoder-percentage-zigbee-version" />
                    </div><br>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row
                v-else-if="selectedGatewayModel === 'SEG-X5'"
                class="mt-30"
              >
                <ion-col>
                  <!-- <div class="welcome-text-intro text-center text--gray middle-content">
                  <span>{{ $t('welcomeText') }}</span>
                </div> -->
                  <div
                    class="text--gray"
                  >
                    <span>Make sure the GW is powered on and the green light is solid. Press both buttons on the GW until you hear beep sound and the GW flashes orange</span>
                    <!-- <span>{{ $t('introTextGWFlashPurple') }}</span> -->
                    <!-- <div
                      class="mt-10 text--gray"
                      style="text-decoration: underline;"
                      @click="openLink"
                    >
                      Gateway manual
                    </div> -->
                  </div>
                </ion-col>
              </ion-row>
              <div
                class="footer"
              >
                <ion-button
                  v-show="selectedGatewayModel"
                  class="margin-5vh-top"
                  @click="checkConnectedWifi"
                >
                  <ion-label>{{ $t('Continue') }}</ion-label>
                </ion-button>
              </div>
            </ion-grid>
          </ion-content>
        </ion-slide>
        <ion-slide>
          <div class="background--login" />
          <ion-content
            v-if="loading === false && permissionGranted === true && selectedGatewayModel === 'SEG-X3'"
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
                @click="previousSlide"
              />
            </ion-toolbar>
            <ion-row>
              <ion-col>
                <ion-text class="text--bold text-larger text--white">
                  {{ $t('Your home WiFi') }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="mt-30">
              <ion-col>
                <ion-text class="welcome-text-intro text--gray">
                  {{ $t('wifiSettingsInstructions') }} <br><br>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="margin-5vh-top">
              <ion-col>
                <div
                  v-if="selectedWifi.includes('5g') || selectedWifi.includes('5G')"
                  class="mb-10 text--bold text--primary"
                >
                  {{ $t('5Ghz Wifis are not supported') }}
                </div>
                <ion-item
                  lines="none"
                  class="ion-margin-bottom input"
                >
                  <!-- <ion-label color="light">
                    Enter your WiFi SSID
                  </ion-label> -->
                  <!-- <ion-select
                    :value="selectedWifi"
                    @ionChange="selectedWifi= $event.target.value"
                  >
                    <ion-select-option
                      v-for="(item, index) in availableWifis"
                      :key="index"
                      :value="item.SSID"
                    >
                      {{ item.SSID }}
                    </ion-select-option>
                  </ion-select> -->
                  <ion-input
                    :placeholder="$t('wifiSSID')"
                    :value="selectedWifi"
                    enterkeyhint="done"
                    @keydown.enter="closeKeyboard"
                    @input="selectedWifi=$event.target.value"
                  />
                </ion-item>
                <ion-item
                  lines="none"
                  class="ion-margin-bottom input"
                >
                  <!-- @input="selectedWifiPassword=$event.target.value" -->
                  <ion-input
                    :placeholder="$t('WiFi Password')"
                    enterkeyhint="done"
                    :value="selectedWifiPassword"
                    @keydown.enter="closeKeyboard"
                    @input="selectedWifiPassword=$event.target.value"
                  />
                </ion-item>
              </ion-col>
              <ion-label class="text--primary">
                {{ $t('wifiSettingsInstructions2') }}
              </ion-label>
            </ion-row>
            <ion-button
              v-show="selectedWifiPassword"
              class="mt-10"
              @click="nextSlide"
            >
              <ion-label>{{ $t('Connect to Gateway') }}</ion-label>
            </ion-button>
          </ion-content>
          <ion-content
            v-if="loading === false && permissionGranted === true && selectedGatewayModel === 'SEG-X5'"
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
                @click="previousSlide"
              />
            </ion-toolbar>
            <ion-row>
              <ion-col>
                <ion-text class="text--bold text-larger text--white">
                  Permission check
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text class="welcome-text-intro text--gray">
                  All checked. You can continue by clicking button below <br><br>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-button
              class="mt-30"
              @click="nextSlide"
            >
              <ion-label>{{ $t('Connect to Gateway') }}</ion-label>
            </ion-button>
          </ion-content>
          <ion-content
            v-else-if="loading"
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <div
              v-if="loading"
              class="text--gray mt-30"
            >
              <ion-spinner
                color="light"
                name="crescent"
              /><br><br>
              {{ $t('Loading') }}....
            </div>
          </ion-content>
          <ion-content
            v-else-if="loading === false && permissionGranted === false"
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <div v-if="platform.includes('ios')">
              <i
                class="mdi mdi-map-marker-alert text--primary"
                style="font-size: 50px;"
              />
              <div class="mt-30">
                {{ $t('We need some additional premissions in order to continue the gateway setup.') }} <br><br>
                {{ $t('iosPermissionsDescription') }}
                <ion-button
                  class="mt-30"
                  @click="requestPermissionsIos"
                >
                  <ion-label>{{ $t('Allow Location') }}</ion-label>
                </ion-button>
              </div>
            </div>
            <div v-else-if="platform.includes('android')">
              <div class="text--white">
                <i
                  class="mdi mdi-map-marker-alert text--primary"
                  style="font-size: 50px;"
                />
                <div class="mt-30">
                  {{ $t('We need some additional premissions in order to continue the gateway setup.') }} <br><br>
                  {{ $t('Please continue by clicking button') }} <br> <span class="text--primary">{{ $t('Request Permissions') }}</span>
                </div>
              </div>
              <ion-button
                class="mt-30"
                @click="requestPermissionsAndroid"
              >
                <ion-label>{{ $t('Request Permissions') }}</ion-label>
              </ion-button>
            </div>
            <div v-else>
              Not allowed
            </div>
          </ion-content>
        </ion-slide>
        <ion-slide>
          <div class="background--login" />
          <ion-content
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
                @click="previousSlide"
              />
              <!-- <ion-text
                v-if="!gwAddFailed"
                class="text-larger text--white"
              >
                {{ $t('Begin gateway setup') }}
              </ion-text> -->
            </ion-toolbar>
            <ion-row>
              <ion-col>
                <ion-text
                  v-if="!gwAddFailed"
                  class="text--bold text-larger text--white"
                >
                  {{ $t('Begin gateway setup') }}
                </ion-text>
                <ion-text
                  v-if="gwAddFailed"
                  class="text--bold text-larger text--white"
                >
                  Någonting har blivit fel
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div
                  v-if="!gwAddFailed && selectedGatewayModel === 'SEG-X3'"
                  class="text--gray"
                >
                  {{ $t('connectToTheGwWifi') }}
                </div>
                <div
                  v-if="gwAddFailed"
                  class="text--primary"
                >
                  {{ $t('connectToTheGwWifiFail') }}
                </div>
                <div class="text--primary mt-10">
                  {{ status }}
                </div>
              </ion-col>
            </ion-row>
            <div v-if="selectedGatewayModel === 'SEG-X3'">
              <div v-if="!gwAddFailed">
                <ion-img
                  v-if="platform.includes('ios')"
                  class="setup-image rounded-corners"
                  :src="iosWifiSetup"
                />
                <ion-img
                  v-else
                  class="setup-image rounded-corners"
                  :src="AndroidWifiSetup"
                />
              </div>
            </div>

            <ion-button
              v-if="gwAddFailed && selectedGatewayModel === 'SEG-X3'"
              class="margin-5vh-top"
              color="success"
              @click="previousSlide"
            >
              <ion-label>1. {{ $t('Check Wifi information') }}</ion-label>
            </ion-button>
            <i
              v-if="gwAddFailed"
              class="mdi mdi-arrow-down text--primary text-20 mt-10"
            />
            <ion-button
              v-if="selectedGatewayModel === 'SEG-X3'"
              class="mt-10"
              @click="connectToGatewayWifi"
            >
              <ion-label><span v-if="gwAddFailed">2.</span> {{ $t('Connect to ZB_GW wifi') }}</ion-label>
            </ion-button>
            <i
              v-if="gwAddFailed"
              class="mdi mdi-arrow-down text--primary text-20 mt-10"
            />
            <ion-button
              v-show="connectClicked && !gwAddFailed && canContinue"
              class="mt-10"
              color="success"
              @click="isConnectedToGatewayWifi"
            >
              <ion-label>{{ $t('Start gateway setup') }}</ion-label>
            </ion-button>

            <div
              v-if="noX5Found"
              class="text--primary mb-10"
            >
              It seems there are no gateways found, is your gateway powered on and in pairing mode?
            </div>

            <ion-button
              v-if="selectedGatewayModel === 'SEG-X5' && !gwAddFailed && canContinue"
              class="mt-10"
              color="success"
              @click="nextSlide"
            >
              <ion-label>{{ $t('Start gateway setup') }}</ion-label>
            </ion-button>

            <ion-button
              v-show="gwAddFailed"
              class="mt-10"
              @click="isConnectedToGatewayWifi"
            >
              <ion-label>3. {{ $t('Try again gateway setup') }}</ion-label>
            </ion-button>
            <div
              v-if="!canContinue"
              class="text--gray"
            >
              <ion-spinner
                color="light"
                name="crescent"
              /><br><br>
              {{ $t('Loading') }}....
            </div>
          </ion-content>
        </ion-slide>
        <ion-slide>
          <div class="background--login" />
          <ion-content
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
                @click="previousSlide"
              />
            </ion-toolbar>
            <div>
              <img
                v-if="selectedGatewayModel === 'SEG-X5'"
                style="max-width: 135px; margin-top: 35px;"
                :src="require('@/assets/devices/gateway2.png')"
              >
              <img
                v-else-if="selectedGatewayModel === 'SEG-X3'"
                style="max-width: 135px; margin-top: 35px;"
                :src="require('@/assets/devices/gateway.png')"
              >
            </div>
            <div
              class="text--primary mt-30"
            >
              <span>{{ $t('Permission check') }}</span>
            </div>
            <div
              v-show="loading"
              style="height: 15vh"
              class="text--gray mt-30"
            >
              <ion-spinner
                color="light"
                name="crescent"
              /><br><br>
              {{ $t('Loading') }}....
            </div>
            <div
              v-show="!loading"
              style="height: 15vh"
              class="mt-30"
            >
              <i
                class="mdi mdi-check-circle-outline text--primary"
                style="font-size: 80px;"
              />
            </div>
            <div>
              <ion-button
                :disabled="loading"
                @click="nextSlide"
              >
                {{ $t('Continue') }}
              </ion-button>
            </div>
            <ion-content />
          </ion-content>
        </ion-slide>
        <ion-slide>
          <div class="background--login" />
          <ion-content
            :scroll-y="false"
            class="ion-padding-start ion-padding-end ion-padding-bottom welcome-content"
          >
            <!-- <ion-toolbar color="transparent">
              <ion-icon
                slot="start"
                color="light"
                class="previous-icon"
                :icon="chevronBackOutline"
              />
            </ion-toolbar> -->
            <ion-row class="mt-30">
              <ion-col>
                <ion-text
                  v-if="!gwAddFailed"
                  class="text--bold text-larger text--white"
                >
                  {{ $t('Configuring gateway') }}
                </ion-text>
                <ion-text
                  v-if="gwAddFailed"
                  class="text--bold text-larger text--white"
                >
                  {{ $t('gwAddFailed') }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text
                  v-if="!gwAddFailed"
                  class="text--gray"
                >
                  {{ $t('We have all we need now.') }} <br>{{ $t('Configuring the gateway..hold tight!') }}
                </ion-text>
                <ion-text
                  v-if="gwAddFailed"
                  class="text--gray"
                >
                  {{ $t('gwAddFailed1') }} <br>{{ $t('gwAddFailed2') }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="configure-gateway">
              <ion-col>
                <div
                  v-show="loader"
                >
                  <div
                    class="loader-wrapper"
                  >
                    <div class="back-circle-loader">
                      <div class="loader-anim" />
                      <div class="inner-loader" />
                      <div class="inner-loader-frame" />
                    </div>
                    <div class="laoder-percentage">
                      <span :class="gwAddFailed ? 'text--danger' : ''">{{ progress }}%</span>
                    </div>
                  </div><br>
                  <ion-label class="setup-status">
                    {{ status }}<br>
                  </ion-label>
                </div>
              </ion-col>
            </ion-row>
            <ion-button
              v-show="gatewayAdded"
              class="margin-5vh-top"
              color="success"
              @click="redirectToMainView"
            >
              <ion-label>{{ $t('Done!') }}</ion-label>
            </ion-button>
            <ion-button
              v-show="gwAddFailed"
              class="margin-5vh-top"
              color="danger"
              @click="goBackToSetupSlide"
            >
              <ion-label>{{ $t('Try again') }}</ion-label>
            </ion-button>
          </ion-content>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, wifiOutline } from 'ionicons/icons'
import { IonSlides, IonSlide, loadingController } from '@ionic/vue'
import { defineComponent } from 'vue'
// import { Plugins } from '@capacitor/core'
import tcpController from '../lib/tcpController'
// import udpController from '../lib/udpController'
import { Zeroconf } from '@ionic-native/zeroconf'
// import wifiController from '../lib/wifiController'
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/'
// import { PushNotifications } from '@capacitor/push-notifications'
import { AndroidPermissions } from '@ionic-native/android-permissions'
import { Diagnostic } from '@ionic-native/diagnostic'
import { Insomnia } from '@ionic-native/insomnia'
import { NetworkInterface } from '@ionic-native/network-interface'
import { InAppBrowser } from '@ionic-native/in-app-browser/'
import { Keyboard } from '@capacitor/keyboard'

import AndroidWifiSetup from '../assets/images/WifiSettingsAndroid.jpg'
import iosWifiSetup from '../assets/images/WifiSettingsIOS.jpg'

export default defineComponent({
  name: 'AddGatewayModal',
  components: {
    IonSlides,
    IonSlide
  },
  data () {
    return {
      wifiWizard2: WifiWizard2,
      AndroidWifiSetup,
      iosWifiSetup,
      androidPermissions: AndroidPermissions,
      diagnostic: Diagnostic,
      insomnia: Insomnia,
      networkPlugin: NetworkInterface,
      chevronForwardOutline,
      chevronBackOutline,
      wifiOutline,
      selectedGatewayModel: '',
      gatewayIp: '',
      tcpPort: '',
      selectedWifi: '',
      selectedWifiPassword: '',
      GWSsid: '',
      status: '',
      progress: 0,
      attempts: 0,
      slideLoader: false,
      loading: true,
      loader: false,
      noX5Found: false,
      loaderPreset: false,
      gatewayAdded: false,
      connectClicked: false,
      connectedWifi: '',
      gwAddFailed: false,
      error: 'error',
      success: 'success',
      ready: false,
      permissionGranted: false,
      canContinue: false
    }
  },
  computed: {
    tcpSocket () {
      return this.$store.state.socketTcpId
    },
    // availableWifis () {
    //   return this.$store.state.availableWifi
    // },
    // gatewayWifiSSID () {
    //   const awailableWifi = this.$store.state.availableWifi
    //   console.log(awailableWifi)
    //   let ssid
    //   if (awailableWifi.length) {
    //     awailableWifi.forEach(element => {
    //       if (element.SSID.includes('ZB_GW')) {
    //         ssid = element.SSID
    //       } else {
    //         ssid = 'No Gateway Wifi found'
    //       }
    //     })
    //   } else {
    //     ssid = 'No WiFi Found'
    //   }
    //   return ssid
    // },
    tcpData () {
      return this.$store.state.tcpData
    },
    gwSessionId () {
      return this.$store.state.gwSessionId
    },
    tcpStatus () {
      return this.$store.state.tcpStatus
    },
    platform () {
      return this.$store.state.platform
      // return ['android']
    }
  },
  watch: {
    tcpData: function (val) {
      console.log(val.result)
      if (val.result === false) {
        this.gwAddFailed = true
      }
    }
  },
  created () {
    document.addEventListener('deviceready', (i) => {
      this.ready = true
    })
    this.ready = true
  },
  mounted () {
    // wifiController.getWifis(window)
  },
  methods: {
    nextSlide () {
      this.$refs.slides.$el.slideNext()
    },
    checkPermissionsThenNextSlide () {
      this.$refs.slides.$el.slideNext()
    },
    previousSlide () {
      this.$refs.slides.$el.slidePrev()
    },
    goBackToSetupSlide () {
      this.$refs.slides.$el.slideTo(3, 600, true)
    },
    async LogOut () {
      // console.log('logout')
      // PushNotifications.removeAllListeners()
      // await this.$store
      //   .dispatch('LogOut')
      //   .then(() => this.$router.replace('/login'))
      this.$router.replace('/main-view')
    },
    async progressPlus (number) {
      for (let i = 0; i < number; i++) {
        await new Promise(resolve => setTimeout(resolve, 100))
        this.progress++
      }
    },
    async checkConnectedWifi (window) {
      if (this.ready) {
        console.log(this.platform)
        if (!this.platform.includes('desktop')) {
          try {
            this.wifiWizard2.getConnectedSSID()
              .then(response => {
                this.success = response
                console.log(response)
                this.selectedWifi = response
                this.nextSlide()
              })
              .catch(error => {
                this.error = error
                console.log(error)
                this.nextSlide()
              })
          } catch (error) {
            this.error = error
            this.nextSlide()
          }
        } else {
          this.nextSlide()
        }
      } else {
        this.error = 'not ready'
        this.nextSlide()
      }
    },
    connectToGatewayWifi () {
      window.cordova.plugins.settings.open('wifi', () => {
        console.log('wifi settings opened')
      })
      this.connectClicked = true
    },
    async whatIsConnectedTo () {
      if (this.ready) {
        try {
          this.wifiWizard2.getConnectedSSID()
            .then(response => {
              this.success = response
              console.log(response)
              this.connectedWifi = response
            })
            .catch(error => {
              this.error = error
              console.log(error)
            })
        } catch (error) {
          this.error = error
        }
      } else {
        this.error = 'not ready'
      }
    },
    async isConnectedToGatewayWifi () {
      if (this.selectedGatewayModel === 'SEG-X3') {
        this.whatIsConnectedTo()
        console.log('repeating')
        if (!this.loaderPreset) {
          this.wifiLoader()
        }
        if (this.attempts <= 99) {
          if (this.connectedWifi.includes('ZB_GW')) {
            this.status = ''
            this.gwAddFailed = false
            this.progress = 0
            this.nextSlide()
            this.dismissLoading()
            this.attempts = 0
          } else {
            console.log('Not connected')
            if (this.attempts <= 99) {
              this.attempts++
            }
            this.repeateFunction()
          }
        } else {
          this.dismissLoading()
          this.attempts = 0
          this.status = this.$t('notConnectedToZBWifi')
        }
      } else if (this.selectedGatewayModel === 'SEG-X5') {
        // this.gatewayIp
        this.status = ''
        this.gwAddFailed = false
        this.progress = 0
        this.nextSlide()
      }
    },
    repeateFunction () {
      setTimeout(() => { this.isConnectedToGatewayWifi() }, 100)
    },
    async wifiLoader () {
      this.loaderPreset = true
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: 'Kontrollerar om appen är ansluten till gateway',
          spinner: 'crescent'
        })

      await loading.present()

      const interval = setInterval(() => { loading.message = `${this.attempts}% Kontrollerar om appen är ansluten till gateway` }, 100)
      setTimeout(() => { clearInterval(interval) }, 100000)
    },
    dismissLoading () {
      this.loaderPreset = false
      loadingController.dismiss()
    },
    requestPermissionsAndroid () {
      this.loading = true
      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        ]
      ).then(
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          result => {
            console.log('Android status permissions')
            console.log('PermissionResult          ' + JSON.stringify(result))
            if (!result.hasPermission) {
              this.permissionGranted = false
              this.loading = false
            } else {
              this.permissionGranted = true
              this.loading = false
            }
          },
          err => {
            console.log(err)
            this.loading = false
            this.previousSlide()
          }
        )
      )
    },
    requestPermissionsIos () {
      window.cordova.plugins.settings.open('locations', () => {
        console.log('wifi settings opened')
      })
      this.permissionGranted = true
    },
    async ionSlideDidChange () {
      this.$refs.slides.$el.getActiveIndex().then(async i => {
        if (i === 0) {
          console.log('slide 0')
          this.loading = true
        }
        if (i === 2) {
          console.log('slide 2')
          this.loading = true
          if (this.platform.includes('android')) {
            this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
              result => {
                console.log('Android status permissions')
                console.log('PermissionResult          ' + JSON.stringify(result))
                if (!result.hasPermission) {
                  this.permissionGranted = false
                  setTimeout(() => {
                    this.loading = false
                  }, 500)
                } else {
                  this.permissionGranted = true
                  setTimeout(() => {
                    this.loading = false
                  }, 500)
                }
              },
              err => {
                console.log(err)
                this.loading = false
              }
            )
          } else if (this.platform.includes('ios')) {
            this.loading = true
            this.diagnostic.isLocationAuthorized().then((isEnabled) => {
              if (isEnabled) {
                this.permissionGranted = true
                console.log('location enabled')
                setTimeout(() => {
                  this.loading = false
                }, 500)
              } else {
                this.permissionGranted = false
                console.log('location disabled')
                setTimeout(() => {
                  this.loading = false
                }, 500)
              }
            })
          } else {
            console.log('others')
          }
        }
        if (i === 3) {
          console.log('slide 3')
          this.loading = true
          if (this.selectedGatewayModel === 'SEG-X3') {
            this.gatewayIp = '192.168.1.1'
            this.tcpPort = 11500
            this.canContinue = true
          } else if (this.selectedGatewayModel === 'SEG-X5') {
            setTimeout(() => {
              if (this.canContinue === false) {
                this.noX5Found = true
              }
            }, 5000)
            Zeroconf.watch('_x5_cfg._tcp.', 'local.').subscribe(result => {
              const gwIpAddress = String(result.service.ipv4Addresses[0])
              const port = Number(result.service.port)
              if (result.action === 'resolved') {
                this.noX5Found = false
                this.gatewayIp = gwIpAddress
                this.tcpPort = port
                this.canContinue = true
              } else {
                console.log(result)
              }
            })
            // this.networkPlugin.getWiFiIPAddress().then(async address => {
            //   const myIp = address.ip
            //   udpController.startUDP(window, myIp)
            //   await new Promise(resolve => setTimeout(resolve, 3000))
            //   const gatewayIp = this.$store.state.gatewayIp
            //   if (gatewayIp === 'error' || gatewayIp === null) {
            //     console.log('error while finding the gateway')
            //   } else {
            //     this.gatewayIp = gatewayIp
            //     console.log(gatewayIp)
            //     this.canContinue = true
            //   }
            // })
          }
        }
        if (i === 4) {
          this.dismissLoading()
          if (!this.tcpSocket) {
            this.loading = true
            tcpController.initiateConnection(window)
            await new Promise(resolve => setTimeout(resolve, 1000))
            tcpController.connectToTheGateway(window, this.tcpSocket, this.gatewayIp, this.tcpPort, this.selectedGatewayModel)
            await new Promise(resolve => setTimeout(resolve, 1000))
            if (this.tcpStatus === 'OK') {
              tcpController.disconnectGateway(window, this.tcpSocket)
              await new Promise(resolve => setTimeout(resolve, 1000))
              this.nextSlide()
            } else {
              await new Promise(resolve => setTimeout(resolve, 5000))
              tcpController.disconnectGateway(window, this.tcpSocket)
              this.loading = false
            }
            this.status = this.$t('Connection initiated')
          } else {
            console.log('TCP Socket connection already craeted, skiping')
            this.nextSlide()
          }
        }
        if (i === 5) {
          console.log('slide 5')
          this.insomnia.keepAwake()
          this.loader = true
          this.progressPlus(10)
          if (this.tcpSocket === null) {
            tcpController.initiateConnection(window)
          }
          await new Promise(resolve => setTimeout(resolve, 1000))
          if (this.tcpStatus !== 'OK') {
            tcpController.connectToTheGateway(window, this.tcpSocket, this.gatewayIp, this.tcpPort, this.selectedGatewayModel)
          }
          await new Promise(resolve => setTimeout(resolve, 3000))
          this.status = this.$t('Logging In To The Gateway')
          if (this.tcpStatus === 'error' || this.tcpStatus === 'notConnected') {
            this.gwAddFailed = true
            this.status = this.$t('Something has failed, are you connected to the gateways wifi?')
          } else if (this.tcpStatus === 'OK') {
            if (this.selectedGatewayModel === 'SEG-X3') {
              const command = '{"argument":{"password":"123456","username":"owon"},"type":"login","sequence":1017}'
              await tcpController.sendCommandToGateway(window, this.tcpSocket, command)
            }
            await new Promise(resolve => setTimeout(resolve, 1000))
            if (!this.gwAddFailed) {
              this.progressPlus(10)
              // if (this.tcpData.result) {
              if (this.selectedGatewayModel === 'SEG-X3') {
                this.$store.dispatch('gwSessionId', this.tcpData.response.session)
              } else {
                this.$store.dispatch('gwSessionId', '1peem39po1ilnk9')
              }
              this.status = this.$t('Successfully logged in')
              this.$store.dispatch('clearTcpData')
              await new Promise(resolve => setTimeout(resolve, 1000))
              const command = `{"type":"netConfig","command":"config","session":"${this.gwSessionId}","sequence":1193,"argument":{"web":"homevision.se","portNum":"4046","ipAddr":"13.53.235.209","sslPortNum":"4047"}}`
              await tcpController.sendCommandToGateway(window, this.tcpSocket, command)
              // } else {
              //   this.status = this.$t('Something has failed please try that again')
              //   this.loader = false
              //   this.gwAddFailed = true
              // }
              this.progressPlus(10)
              await new Promise(resolve => setTimeout(resolve, 1000))

              // if (this.tcpData.result) {
              if (this.selectedGatewayModel === 'SEG-X3') {
                this.status = this.$t('Setting Up Gateways Wifi')
                this.$store.dispatch('clearTcpData')
                const command2 = `{"type":"wifiConfig","command":"sta","session":"${this.gwSessionId}","sequence":1002,"argument":{"ssid":"${this.selectedWifi}","sskey":"${this.selectedWifiPassword}"}}`
                await tcpController.sendCommandToGateway(window, this.tcpSocket, command2)
              }
              // } else {
              //   this.status = this.$t('Something has failed please try that again')
              //   this.loader = false
              //   this.gwAddFailed = true
              // }
              this.progressPlus(10)
              await new Promise(resolve => setTimeout(resolve, 1000))

              // if (this.tcpData.result) {
              this.status = this.$t('Setting Up Gateways Time Zone')
              this.$store.dispatch('clearTcpData')
              const command3 = `{"type":"system","command":"setTimeZone","session":"${this.gwSessionId}","sequence":1024,"argument":{"area":"Europe/Prague","timezone":1,"dst":false}}`
              await tcpController.sendCommandToGateway(window, this.tcpSocket, command3)
              // } else {
              //   this.status = this.$t('Something has failed please try that again')
              //   this.loader = false
              //   this.gwAddFailed = true
              // }

              const now = new Date()
              const then = new Date('2000-01-01')
              const diff = now.getTime() - then.getTime()
              const currentTime = Math.round(diff / 1000)
              this.progressPlus(30)
              await new Promise(resolve => setTimeout(resolve, 1000))

              // if (this.tcpData.result) {
              this.status = this.$t('Setting Up Gateways Time')
              this.$store.dispatch('clearTcpData')
              const command4 = `{"type":"system","command":"setTime","session":"${this.gwSessionId}","sequence":1024,"argument":{"utc":${currentTime}}}`
              await tcpController.sendCommandToGateway(window, this.tcpSocket, command4)
              // } else {
              //   this.status = this.$t('Something has failed please try that again')
              //   this.loader = false
              //   this.gwAddFailed = true
              // }

              await new Promise(resolve => setTimeout(resolve, 1000))

              // if (this.tcpData.result) {
              this.status = this.$t('Launching Gateway')
              this.$store.dispatch('clearTcpData')
              const command5 = `{"type":"netConfig","command":"register","session":"${this.gwSessionId}","sequence":1195,"argument":{"username":"${this.$store.state.propertyId}","password":"${this.$store.state.userId}","agentid":"${this.$store.state.agentId}"}}`
              await tcpController.sendCommandToGateway(window, this.tcpSocket, command5)

              await new Promise(resolve => setTimeout(resolve, 5000))
              this.closeTcp(window, this.tcpSocket)
              // } else {
              //   this.status = this.$t('Something has failed please try that again')
              //   this.loader = false
              //   this.gwAddFailed = true
              // }
              this.progressPlus(30)
              this.status = this.$t('Gateway started, please hold on')
              await new Promise(resolve => setTimeout(resolve, 4000))
              this.presentLoading()

              this.loader = false
              await new Promise(resolve => setTimeout(resolve, 45000))

              this.$store.dispatch('addGateway', this.selectedGatewayModel)
              await new Promise(resolve => setTimeout(resolve, 15000))
              this.$store.dispatch('getUserData')

              this.wifiWizard2.getConnectedSSID()
                .then(response => {
                  if (response.includes('ZB_GW')) {
                    this.gwAddFailed = true
                    this.connectedWifi = ''
                    // this.status = this.$t('failedWifiSetup'
                    this.status = ''
                    this.insomnia.allowSleepAgain()
                  } else {
                    this.$store.dispatch('wasGatewayAdded').then(resp => {
                      console.log(resp)
                      if (resp.data === 'added') {
                        this.gatewayAdded = true
                        this.insomnia.allowSleepAgain()
                      } else {
                        this.gwAddFailed = true
                        this.connectedWifi = ''
                        // this.status = this.$t('failedWifiSetup')
                        this.status = ''
                        this.insomnia.allowSleepAgain()
                      }
                    })
                  }
                })
                .catch(error => {
                  this.error = error
                  console.log(error)
                  this.$store.dispatch('wasGatewayAdded').then(resp => {
                    console.log(resp)
                    if (resp.data === 'added') {
                      this.gatewayAdded = true
                      this.insomnia.allowSleepAgain()
                    } else {
                      this.gwAddFailed = true
                      this.connectedWifi = ''
                      // this.status = this.$t('failedWifiSetup')
                      this.insomnia.allowSleepAgain()
                    }
                  })
                })
            }
          } else {
            this.gwAddFailed = true
            this.status = this.$t('Something has failed, are you connected to the gateways wifi?')
          }
        }
      })
    },
    redirectToMainView () {
      this.$router.push({ path: 'main-view' })
      tcpController.closeTcp(window)
      this.$store.commit('gatewayProgress', false)
    },
    async presentLoading () {
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: 'Väntar på att gateway ansluter till servern, detta kan ta upp till 1 minuter. Stäng inte ner appen.',
          spinner: 'crescent',
          duration: this.timeout
        })

      await loading.present()

      setTimeout(function () {
        loading.dismiss()
      }, 61000)
    },
    closeTcp (window, socket) {
      tcpController.closeTcp(window, socket)
    },
    openLink () {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/Gateway+manual.pdf'
      } else {
        linkToOpen = 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/Gateway+manual.pdf'
      }
      InAppBrowser.create(linkToOpen).show()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import "~@/theme/_backgrounds";

ion-slide {
 padding-top: 20px;
}

.welcome {
  &-content {
    top: 2%;
  }
}

.back-button {
  height: 60px;
  width: 110px;
  font-size: 25px;
}

.next-icon {
  background-color: colors(primary, base);
  padding: 8px 10px 8px 10px;
  border-radius: $radius-sm;
}

.connect-gateway {

  &-button {
    height: 250px;
    width: 250px;
    font-size: 60px;
  }

  &-icon {
    padding: 10px;
    border-radius: $radius-sm;
    background-color: rgba(0, 117, 218, 0.7);
  }
}

.slides {
  height: 100%;
  width: 100%;
}

.configure-gateway {
  margin-top: 10vh;
}

.setup-status {
  color: white;
}

ion-spinner {
  width: 60px !important;
  height: 60px !important;
}

/// Ostre jako prase////////
$offset: 30px;
.middle-content {
  justify-content: center;
  padding: 0 $offset;
  img {
    padding-bottom: $offset;
  }
}
.previous-icon {
  font-size: 30px;
}

.input {
  --background: rgba(44, 48, 54, 0.6);
  border-radius: 5px;
}
ion-input {
  --color: white;
}

ion-select {
  color: white;
}

ion-button {
  min-height: 50px;
}
.loader-wrapper {
  position: relative;
}
.back-circle-loader {
  position: relative;
  z-index: -1;
  height: 214px;
  width: 214px;
  background: linear-gradient(180deg, #ffd5a433 0%, #f4b87421 100%);
  // opacity: 0.1;
  border-radius: 50%;
  display: inline-block;
}
.spacer-top {
  margin-top: 5vh;
}
.loader-anim {
  position: relative;
  top: 7px;
  left: 7px;
  border: 8px solid #f3f3f300;
  border-top: 8px solid #ffd4a4;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-animation: spinAnim 1s linear infinite;
  animation: spinAnim 1s linear infinite;
}
@keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
.loader-anim-zigbee-version {
  position: relative;
  top: 7px;
  left: 7px;
  // border: 8px solid #f3f3f300;
  border: 8px solid purple;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 20px purple, inset 0 0 20px purple;
  animation: blinker 2s step-start infinite;
  // -webkit-animation: spinAnim 1s linear infinite;
  // animation: spinAnim 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.laoder-percentage {
  color: white;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 52px;
  line-height: 61px;
}
.laoder-percentage-zigbee-version {
  color: white;
  z-index: 4;
  position: absolute;
  top: 53%;
  left: 51%;
  transform: translate(-53%, -51%);
  font-weight: bold;
  font-size: 52px;
  line-height: 61px;
}
.inner-loader {
  z-index: 3;
  position: absolute;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 164px;
  width: 164px;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(42px);
  border-radius: 50%;
}
.inner-loader-frame {
  position: absolute;
  z-index: 5;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background: radial-gradient(100% 100% at 0% 0%, rgba(206, 206, 206, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(150, 150, 150, 0.205);
}
.model-button {
  padding: 5px;
  min-height: 150px;
  @include bg-box-item;
  background-color: #{transparentize(colors(dark, shade), 0.3)};
}

.active {
  border: 1px solid colors(primary, base);
  background-color: #{transparentize(colors(primary, base), 0.9)};
}

.grid {
  height: 100%;
}
.row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.footer {
        position: sticky;
        height: 200px;
        top: calc( 100vh - 200px );
    }

@media (max-height: 750px) {
  .loader-wrapper {
    transform: scale(0.7);
  }
  .spacer-top {
    margin-top: 0vh;
  }
  .setup-image {
    max-width: 250px;
    margin: 0 auto;
  }
}
</style>
